<template>
  <div class="layout" :style="colorObject">
    <div class="container">
      <Loading
        v-if="isLoading"
        loading-message="載入中..."
        :background-color="'#ffffff'"
      />
      <header class="border-bottom">
        <div class="d-flex justify-content-between align-items-center p-2 px-3">
          <router-link class="logo" :to="{ name: 'LiffPreorderWelcome' }">
            <img :src="meta.image" />
          </router-link>
        </div>
      </header>
      <router-view style="display: flex; flex-direction: column; flex: 1 1 auto;" v-if="preorder"/>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Page/Liff/Shared/Loading";
import { themeColorFn } from "@/mixins/liff/themeColor";
import { mapActions, mapGetters, mapState } from "vuex";
import deepGet from "lodash/get";

export default {
  components: {
    Loading,
  },
  mixins: [themeColorFn({ themeConfigPage: "preorder" })],
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
      getModule: "liffModule/getModule",
    }),
    ...mapState({
      user: (state) => state.liffAuth.user,
    }),
    ...mapState('liffPreorder', { preorder: 'configuration' }),
    moduleConfig() {
      return this.getModule("liff_checkin").module_config || {};
    },
    preorderId() {
      return this.$route.params.preorderId
    },
  },
  async mounted() {
    await this.fetchConfiguration(this.preorderId)

    const checkRegisteredPemission = this.deepGet(this.preorder, 'config.permissions', []).includes('registered')

    if (checkRegisteredPemission && !this.user.registered_at) {
      this.$router.push({ name: "LiffRegisterIndex" });
    } else {
      this.isLoading = false
    }
  },
  methods: {
    deepGet,
    ...mapActions('liffPreorder', ['fetchConfiguration']),
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";
@import url(//fonts.googleapis.com/earlyaccess/notosanstc.css);

.layout {
  background-color: var(--liff-layout_bg_color);
}

.container {
  display: flex;
  flex-direction: column;
  max-width: 768px;
  min-height: 100vh;
  padding: 0;
  font-family: "Noto Sans TC", "Helvetica";
  background-color: var(--liff-content_bg_color);
}

header {
  position: relative;

  .logo {
    max-height: 40px;
    max-width: 40vw;

    img {
      max-height: 40px;
    }
  }
}
</style>
